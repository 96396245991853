import { useEffect, useRef } from 'react'
import { useLocation } from 'react-router'

import useValuesSelector from '../../redux/common/useValuesSelector'
import { version as appVersion } from '../environment'
import { reloadPage } from '../utils/urlUtils'

const useVersionRefresh = (): void => {
  const { version } = useValuesSelector('localStorage', ['version'])
  const versionChanged = appVersion !== version
  const { pathname } = useLocation()
  const oldPath = useRef(pathname)

  useEffect(() => {
    // 3 hours
    const duration = 1000 * 60 * 60 * 3

    let timer: number | null = null
    if (versionChanged) {
      console.log('Version changed, reloading...')
      console.log('redux version:', version)
      console.log('env version:', appVersion)
      timer = window.setTimeout(() => {
        window.addEventListener('focus', () => reloadPage())
        document.hasFocus() && reloadPage()
      }, duration)
    }
    return (): void => {
      timer && clearTimeout(timer)
    }
  }, [versionChanged, version])

  useEffect(() => {
    if (versionChanged && oldPath.current !== pathname) {
      reloadPage(pathname)
    }
    if (oldPath.current !== pathname) {
      oldPath.current = pathname
    }
  }, [pathname, oldPath, versionChanged])
}

export default useVersionRefresh
